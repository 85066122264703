import React from 'react'
import styled from 'styled-components'
import FadeUpWhenVisible from './FadeUpWhenVisible'

const Container = styled.div`

`

const Child = styled(FadeUpWhenVisible)`
  background-image: url('/images/services/0_Home_service/banner-services-save.jpg');
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  min-height: 215px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 10px 10px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-image: url('/images/services/0_Home_service/banner-services-save.jpg');
    max-width: 918px;
    min-height: 374px;
    padding: 30px 45px;
  }
`

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 37px;
    line-height: 44px;
    text-shadow: 0 4px 14px #000000;
  }
`

interface Props {
  children: React.ReactNode
}

const ServicesHomeBanner: React.FC<Props> = ({ children }) => (
  <Container>
    <Child>
      <Title>{children}</Title>
    </Child>
  </Container>
)

export default ServicesHomeBanner
