import React from 'react'
import styled from 'styled-components'
import { ServiceItems } from '../data/services'
import theme from '../theme/theme'
import AspectRatio from './AspectRatio'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import { Link } from 'gatsby'

const ServicesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;

  gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 13px;
  }

  & > :nth-child(8)  {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: grid;
    }
  }
`

interface ServiceLinkProps {
  image: string
}

const ServiceLink = styled(Link)<ServiceLinkProps>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  max-width: 166px;
  max-height: 166px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 198px;
    max-height: 190px;
  }
`

const AspectRatioWrapper = styled(AspectRatio)`
  max-width: 166px;
  max-height: 166px;
  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 198px;
    max-height: 190px;
  }
`

const ServiceLabel = styled.span`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 3px #000000;
  font-size: 16px;
  line-height: 19px;
`

interface ServiceCardProps {
  label: string
  href: string
  image: string
  index: number
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  label,
  href,
  image,
  index,
}) => (
  <AspectRatioWrapper
    desktop={{ width: 198, height: 190 }}
    mobile={{ width: 166, height: 166 }}
  >
    <FadeUpWhenVisible delay={index * theme.transitions.fadeUp.delay}>
      <ServiceLink image={image} to={href}>
        <ServiceLabel>{label}</ServiceLabel>
      </ServiceLink>
    </FadeUpWhenVisible>
  </AspectRatioWrapper>
)

const ServicesGridWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 21px;
    padding-bottom: 25px;
  }
`

interface ServicesGridProps {
  items: ServiceItems
}

const ServicesGrid: React.FC<ServicesGridProps> = ({ items }) => (
  <ServicesGridWrapper>
    <ServicesList>
      {items.map((item, index) => (
        <ServiceCard
          key={index}
          label={item.label}
          href={item.url}
          image={item.image}
          index={index}
        />
      ))}
    </ServicesList>
  </ServicesGridWrapper>
)

export default ServicesGrid
